<template>
    <div>
        <v-card width="1300" height="820" max-height="820" class="pa-10">
            <v-row>
                <v-card-title>
                    {{pTitle}}
                </v-card-title>
            </v-row>
            <v-row>
                <v-col>
                    <v-slider
                        v-model="slider.val"
                        :label="slider.label"
                        :thumb-color="slider.color"
                        thumb-label="always"
                        max="1000000"
                    >
                        <template v-slot:append>
                            <v-text-field
                                v-model="slider.val"
                                class="mt-0 pt-0"
                                type="number"
                                style="width: 80px"
                            ></v-text-field>
                        </template>

                    </v-slider>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        elevation="2"
                        @click="computing()"
                    >
                        Compute
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-card-text>
                    Reached level : {{this.reachedLevel}} <br/>
                    Total completed : {{this.questCompletedList.length}} <br/>
                    Median (count completed/game level) : {{this.median}} <br/>
                </v-card-text>
                <v-card-title>
                    Total reward: {{ this.totalReward.ToReadableAlphabetV2() }}
                </v-card-title>
            </v-row>
            <v-row>

                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-combobox
                        v-model="itemFilterChosen"
                        :items="itemFilterSelect"
                        chips
                        clearable
                        label="Filter by tags"
                        multiple
                        solo
                    >

                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                @click="select"
                                @click:close="removeItemFilterChosen(item)"
                            >
                                <strong>{{ item.text }}</strong>&nbsp;
                            </v-chip>
                        </template>

                    </v-combobox>
                </v-col>
            </v-row>


            <v-card height="500" class="scroll pl-10" elevation="0">
                <v-row>
                    <v-col>
                        <li v-for="item in this.questCompletedList.slice().reverse()" :key=" item.id + item.questTitleCode + item.level + item.KPIGameplayBossLevel">
                            {{ item.questTitleCode }} (level:{{ item.level }}, kpi:{{ item.KPIGameplayBossLevel }}, reward: {{item.reward.ToReadableAlphabetV2()}})
                        </li>
                    </v-col>
                </v-row>
            </v-card>


            <v-card-actions>
                <v-btn color="blue darken-1" text @click="exportJson()">Export</v-btn>
                <v-btn color="blue darken-1" text @click="dispatchClose()">Close</v-btn>
            </v-card-actions>

        </v-card>

        <v-overlay :value="isLoading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>

        </v-overlay>
    </div>
</template>

<script>
import lodash from "lodash";
import SMPGamePlayQuest from "@/quest-editor/questImpl/SMPGamePlayQuest";
import SMPNum from "@/SMPNum";
import DataLoader from "@/quest-editor/dao/DataLoader";
import QuestSimulationService from "@/quest-editor/services/QuestSimulationService";

export default {
    name: "QuestSimulationView",
    props: {
        'pQuestList': {
            type: Array,
            required: true
        },
        'pTitle':{
            type: String,
            required: false,
        },
        'pDailyGameLevelPerDay':{
            type: Number,
            default: 0,
            required: false,
        },
        'pLogicSimulatorService':{
            type: Object,
            required: true
        },
    },
    data() {
        return {
            reachedLevel:0,
            median: 0,
            isLoading: false,
            itemFilterChosen: [],
            itemFilterSelect: [],
            slider: {label: 'Level', val: 1, color: 'red'},
            questOriginalList: [],
            gameQuestList: [],
            questComputedList: [],
            questCompletedList: [],
            totalReward: null,
            rewardedByLevel:[],
        }
    },

    computed: {
    },
    methods: {
        exportJson () {
            let dataStr = JSON.stringify(this.rewardedByLevel);
            DataLoader.exportData(dataStr);
        },
        dispatchClose () {
            this.$emit('close');
        },
        sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        async computing() {
            this.questCompletedList = [];
            this.totalReward = SMPNum.fromNum(0);
            this.median = 0;
            this.reachedLevel = 0;

            let simulationService = new QuestSimulationService(
                lodash.cloneDeep(this.pQuestList)
                , this.pLogicSimulatorService
                , this.$store.state.progressTitle
                , this.$store.state.progressMainTitle
                , this.$store.state.tutorialTitle
            );

            /*
            let result = simulationService.computeRewardForAllQuests(this.slider.val);
            this.totalReward = result.reward;
            this.median = result.median;*/

            simulationService.initGameQuests();

            let dailyGameLevelPerDay = this.pDailyGameLevelPerDay;

            let result = await simulationService.computeRewardForAllQuestsStrategy2(this.slider.val - 1, dailyGameLevelPerDay, this.itemFilterChosen
                , (rs) => {
                    this.totalReward = rs.reward;
                    this.median = rs.median;
                    this.reachedLevel = rs.reachedLevel;
                });
            this.questCompletedList = result.completedData;
        },
        removeItemFilterChosen(item) {
            this.itemFilterChosen.splice(this.itemFilterChosen.indexOf(item), 1)
        },
        initGameQuest () {
            //build GameQuest, insert quest json into game quest
            this.gameQuestList = [];
            this.questOriginalList.forEach(q => {
                let gameQuest = new SMPGamePlayQuest(
                    lodash.cloneDeep(q)
                    , this.pLogicSimulatorService
                    , this.$store.state.progressTitle
                    , this.$store.state.progressMainTitle
                    , this.$store.state.tutorialTitle
                    , this.logicSimulatorService);

                this.gameQuestList.push(gameQuest);
            });
        },
        initComputedQuest () {
            this.questComputedList = [];
            this.gameQuestList.forEach(gq => {
                let shouldCheck = true;
                if (this.itemFilterChosen.length > 0) {
                    let matches = this.itemFilterChosen.filter(eFilter => eFilter.value === gq.questId);
                    if (matches.length > 0) {
                        shouldCheck = true;
                    } else {
                        shouldCheck = false;
                    }
                }
                if (shouldCheck) {
                    let computed = gq.getComputedQuestCurrentLevel();
                    this.questComputedList.push(computed);
                }
            });
        },
        initFilterSelect () {
            this.gameQuestList.forEach(gameQuest => {
                this.itemFilterSelect.push({text: gameQuest.getFullLabel(null, true), value: gameQuest.questId});
            });

        },

        loadData: function (list) {
            //build original quest json
            this.questOriginalList = [];
            if (Array.isArray(list)) {
                list.forEach(e => {
                    this.questOriginalList.push(lodash.cloneDeep(e));
                });
            }
        },
    },


    created() {
        this.totalReward = SMPNum.fromNum(0);
        this.loadData(this.pQuestList);
        this.initGameQuest();
        this.initFilterSelect();
    }
}
</script>

<style scoped>
.scroll {
    overflow-y: scroll
}
</style>