var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-dialog',{attrs:{"scrollable":""},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.showDetailQuest),callback:function ($$v) {_vm.showDetailQuest=$$v},expression:"showDetailQuest"}},[(_vm.showDetailQuest)?_c('questRepeatedView',{attrs:{"pSelectedQuest":this.selectedQuest,"pLogicSimulatorService":_vm.logicSimulatorService}}):_vm._e()],1),_c('v-card',{staticClass:"overflow-y-auto",attrs:{"max-height":"700"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.questList,"hide-default-footer":"","options":{itemsPerPage:_vm.questList.length},"search":_vm.search,"show-group-by":"","show-select":"","item-class":_vm.rowClass},on:{"click:row":_vm.didClickOnRow},scopedSlots:_vm._u([{key:"item.mainTitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mainTitle)+" "),_c('v-edit-dialog',{attrs:{"return-value":item.mainTitle},on:{"update:returnValue":function($event){return _vm.$set(item, "mainTitle", $event)},"update:return-value":function($event){return _vm.$set(item, "mainTitle", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('pre',[_vm._v("                      "+_vm._s(item)+"\n\n                    ")])]},proxy:true}],null,true)})]}},{key:"item.difficulty",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.difficulty},on:{"update:returnValue":function($event){return _vm.$set(item, "difficulty", $event)},"update:return-value":function($event){return _vm.$set(item, "difficulty", $event)}}},[_vm._v(" "+_vm._s(item.difficulty)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.type},on:{"update:returnValue":function($event){return _vm.$set(item, "type", $event)},"update:return-value":function($event){return _vm.$set(item, "type", $event)}}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.formulaType",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.formulaType},on:{"update:returnValue":function($event){return _vm.$set(item, "formulaType", $event)},"update:return-value":function($event){return _vm.$set(item, "formulaType", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_vm._v(" "+_vm._s(item.formulaType)+" "),_c('v-select',{attrs:{"items":[
                                                'Arithmétique','Géométrique','KPI'
                                            ],"label":"Formule"},model:{value:(item.formulaType),callback:function ($$v) {_vm.$set(item, "formulaType", $$v)},expression:"item.formulaType"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.formulaType)+" ")])]}},{key:"item.questTitleCode",fn:function(ref){
                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFullLabel(item))+" ")]}},{key:"item.ordered",fn:function(ref){
                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRewardValue(item))+" ")]}},{key:"item.target",fn:function(ref){
                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFullLabelChallenge(item))+" ")]}}])}),_c('v-dialog',{attrs:{"scrollable":""},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.showSimulation),callback:function ($$v) {_vm.showSimulation=$$v},expression:"showSimulation"}},[(_vm.showSimulation)?_c('simulationView',{attrs:{"pQuestList":this.pQuestList,"pTitle":"Mini Quest Simulator","pLogicSimulatorService":this.logicSimulatorService},on:{"close":function($event){return _vm.close()}}}):_vm._e()],1),_c('v-dialog',{attrs:{"scrollable":""},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.showDailyQuestSimulation),callback:function ($$v) {_vm.showDailyQuestSimulation=$$v},expression:"showDailyQuestSimulation"}},[(_vm.showDailyQuestSimulation)?_c('simulationView',{attrs:{"pQuestList":this.pDailyQuestList,"pTitle":this.getDailyQuestTitle(),"pDailyGameLevelPerDay":this.$store.state.ratios.dailyGameLevelPerDay,"pLogicSimulatorService":_vm.logicSimulatorService},on:{"close":function($event){return _vm.close()}}}):_vm._e()],1)],1),_c('v-divider'),_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Total quests: "+_vm._s(_vm.questList.length)+" ")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.didClickSimulation()}}},[_vm._v("Mini Quest Simulation")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.didClickMiniQuestSimulation()}}},[_vm._v("Daily Quest Simulation")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }