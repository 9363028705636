<template>
    <v-card>
        <v-card-title>Quest: {{ getSelectedQuestTitle()  }}</v-card-title>
        <v-data-table

            :headers="headersDetail"
            :items="detailQuest.list"

            class="elevation-1 petList"

            hide-default-footer

            :options="{itemsPerPage:200}"

            show-group-by
        >

            <template v-slot:item.challengeTarget="{item}">
                {{ getDetailQuestTargetValue(item) }}
            </template>

        </v-data-table>
    </v-card>
</template>

<script>
import SMPGamePlayQuest from "@/quest-editor/questImpl/SMPGamePlayQuest";
import lodash from "lodash";

export default {
    name: "QuestRepeatedView",
    props:['pSelectedQuest', 'pLogicSimulatorService'],
    data () {
        return {
            headersDetail: [
                {text: 'Level', value: 'level', groupable: false},
                {text: 'Objectives', value: 'questTitleCode',groupable: false},
                {text: 'Challenge', value: 'challengeTarget', groupable: false},
                {text: 'KPI Boss Level', value: 'KPIGameplayBossLevel', groupable: false},
                {text: 'Level Should Appear', value: 'KPIGameLevelShouldAppear', groupable: false},
                {text: 'Jumping level', value: 'JumpingLevel', groupable: false},
                {text: 'Reward', value: 'reward', groupable: false}
            ],
        }
    },
    computed: {
        detailQuest: function () {
            let quest = this.pSelectedQuest;
            let listDetail = [];
            if (quest) {
                let gameplayQuest = new SMPGamePlayQuest(
                    lodash.cloneDeep(quest),
                    this.pLogicSimulatorService,
                    this.$store.state.progressTitle,
                    this.$store.state.progressMainTitle,
                    this.$store.state.tutorialTitle
                )
                listDetail = gameplayQuest.computeQuestEvolutionSimulation(10);
            }
            return listDetail;
        }
    },
    methods: {

        getSelectedQuestTitle : function () {
            return this.pSelectedQuest?(this.pSelectedQuest.id):'n/a';
        },
        getDetailQuestTargetValue : function(item) {
            if (!item.challengeTarget) {
                return item.challengeTarget;
            }
            if (item.challengeTarget.ToReadableAlphabetV2 ) {
                return item.challengeTarget.ToReadableAlphabetV2();
            }else {
                return item.challengeTarget;
            }

        },
    }
}
</script>

<style scoped>

</style>