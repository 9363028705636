<template>
    <v-app>
        <questListView
                :pQuestList="this.pQuestList"
                :pDailyQuestList="this.pDailyQuestList"
        />
        <v-footer app>
            <!-- -->

            <v-row>
                <v-col align="right">
                    <router-link  style="text-decoration: none; color: inherit;"
                                  to='/'>
                        <v-icon
                            color=red>
                            mdi-exit-to-app
                        </v-icon>
                    </router-link>
                </v-col>
            </v-row>


        </v-footer>
    </v-app>
</template>

<script>

import QuestListView from "@/quest-editor/modules/quest-list/QuestListView";

export default {
    name: "QuestEditor",
    props:["pSkillList","pIconList","pQuestList", "pDailyQuestList"],
    components: {
        'questListView': QuestListView,
    },
}
</script>

<style scoped>

</style>